import { Box, Container, Typography, Avatar } from '@mui/material';

const testimonials = [
  {
    quote: "Brett Dashevsky co-sign on Siftsy 💬 you def need to check it out Jen Winston 👀💯",
    author: "Austin Null",
    role: "Strategy Director @ McKinney",
    avatar: "/avatars/AustinNull.png" // You'll need to add this image
  },
  {
    quote: "The multi-platform analytics have saved us countless hours of manual work. It's become an essential part of our toolkit.",
    author: "Marcus Rodriguez",
    role: "Influencer Campaign Manager",
    avatar: "/avatars/marcus.jpg" // You'll need to add this image
  }
];

const TestimonialSection = () => {
  return (
    <Box sx={{ py: 12, bgcolor: '#F8F7FF' }}>
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          sx={{
            textAlign: 'center',
            fontSize: { xs: '32px', md: '48px' },
            fontWeight: 600,
            mb: 8,
            color: '#1A1A1A'
          }}
        >
          What our customers say
        </Typography>
        
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', md: 'row' },
          gap: 4,
          justifyContent: 'center'
        }}>
          {testimonials.map((testimonial, index) => (
            <Box
              key={index}
              sx={{
                bgcolor: 'white',
                p: 4,
                borderRadius: 2,
                maxWidth: '500px',
                boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.08)',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: '0px 12px 28px rgba(0, 0, 0, 0.12)',
                },
                position: 'relative',
                // '&::before': {
                //   content: '"""',
                //   position: 'absolute',
                //   top: -20,
                //   left: 24,
                //   fontSize: '80px',
                //   color: '#4728c4',
                //   opacity: 0.1,
                //   fontFamily: 'serif',
                // },
              }}
            >
              <Typography
                sx={{
                  fontSize: '18px',
                  color: '#4D4D4D',
                  mb: 4,
                //   fontFamily: '"Times New Roman Condensed", "Times New Roman", serif',
                  lineHeight: 1.6
                }}
              >
                "{testimonial.quote}"
              </Typography>
              
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Avatar
                  src={testimonial.avatar}
                  alt={testimonial.author}
                  sx={{ width: 56, height: 56 }}
                />
                <Box>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      color: '#1A1A1A',
                      fontSize: '16px'
                    }}
                  >
                    {testimonial.author}
                  </Typography>
                  <Typography
                    sx={{
                      color: '#808080',
                      fontSize: '14px'
                    }}
                  >
                    {testimonial.role}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default TestimonialSection; 