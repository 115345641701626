import { Box, Button, Container, Typography } from '@mui/material';

const CTASection = () => {
  return (
    <Box sx={{ 
      bgcolor: '#4728c4',
      py: { xs: 8, sm: 10, md: 12 },
      px: { xs: 2, sm: 3, md: 4 },
      mt: 8,
      position: 'relative',
      overflow: 'hidden',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'url("/pattern.svg")',
        opacity: 0.1,
        backgroundSize: '1000px',
        backgroundRepeat: 'repeat',
        transform: 'rotate(-5deg)',
        backgroundPosition: '30% 20%',
      }
    }}>
      <Container maxWidth="lg" sx={{ px: { xs: 2, sm: 3, md: 4 } }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '32px', md: '48px' },
              fontWeight: 600,
              color: 'white',
              mb: 2,
              maxWidth: '800px',
              lineHeight: 1.2
            }}
          >
            Say bye to the manual grind.
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '20px', md: '24px' },
              color: '#E6E0FF',
              mb: 6,
              maxWidth: '800px',
              lineHeight: 1.4
            }}
          >
            Make wrap reports a breeze with Siftsy's powerful analytics.
          </Typography>

          <Box sx={{ display: 'flex', gap: 3, flexWrap: 'wrap', justifyContent: 'center' }}>
            <Button
              variant="contained"
              sx={{
                bgcolor: 'white',
                color: '#6039CF',
                px: 4,
                py: 1.5,
                fontSize: '16px',
                fontWeight: 600,
                '&:hover': {
                  bgcolor: '#F8F7FF',
                },
                borderRadius: '8px',
                textTransform: 'none',
              }}
            >
              Try for Free
            </Button>
            <Button
              variant="outlined"
              sx={{
                color: 'white',
                px: 4,
                py: 1.5,
                fontSize: '16px',
                fontWeight: 600,
                borderColor: 'white',
                '&:hover': {
                  borderColor: 'white',
                  bgcolor: 'rgba(255, 255, 255, 0.1)',
                },
                borderRadius: '8px',
                textTransform: 'none',
              }}
            >
              Request a Demo
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CTASection; 