import { Box, Grid, Typography, Chip } from '@mui/material';
import { keyframes } from '@mui/system';

const float = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const FeatureCards = () => {
  const features = [
    {
      title: 'Extract key insights',
      src: `./feature1.svg`,
      delay: '0s'
    },
    {
      title: 'Search & filter',
      summary: 'Shortcuts',
      src: `./feature2.svg`,
      delay: '0.2s'
    },
    {
      title: 'Save & export',
      src: `./feature3.svg`,
      delay: '0.4s'
    }
  ];

  return (
    <Grid container spacing={4} sx={{ position: 'relative', zIndex: 1 }}>
      {features.map((feature, index) => (
        <Grid item xs={12} md={4} key={index}>
          <Box
            sx={{
            //   boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.08)'
              height: '100%',
              animation: `${float} 3s ease-in-out infinite`,
              animationDelay: feature.delay,
            }}
          >
            <Typography align="center" variant="h6" sx={{ mb: 2 }}>{feature.title}</Typography>
            <img src={feature.src} style={{width:"100%"}}/>
          
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default FeatureCards; 