import { Box, Button, Container, Typography } from '@mui/material';
import Header from './Header';
import TrustedBy from './TrustedBy';
import SentimentSection from './SentimentSection';
import UploadSection from './UploadSection';
import TableViewSection from './TableViewSection';
import FeaturesSection from './FeaturesSection';
import TestimonialSection from './TestimonialSection';
import Footer from './Footer';
import ScrollToTop from './ScrollToTop';
import FloatingMetricsCards from './FloatingMetricsCards';
import CTASection from './CTASection';

const LandingPage = () => {
  return (
    <Box sx={{ 
      bgcolor: '#FFFFFF', 
      width: '100%', 
      overflow: 'hidden',
      scrollBehavior: 'smooth'
    }}>
      <Header />
      <Container maxWidth="lg" sx={{ px: { xs: 2, sm: 3, md: 4 } }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            pt: { xs: 8, sm: 10, md: 12 },
            pb: { xs: 4, sm: 5, md: 6 },
            position: 'relative',
          }}
        >
          <Typography
            variant="h1"
            component="h1"
            color="primary"
            sx={{
              mb: 2,
              fontSize: { xs: '40px', md: '64px' },
              fontWeight: 700,
              lineHeight: 1.1,
            //   color: '#1A1A1A',
              maxWidth: '800px',
              position: 'relative',
              letterSpacing:-3,
            }}
          >
            Turn comment sections into{' '}
            <Typography
              component="span"
              variant="inherit"
              sx={{
                background: 'linear-gradient(90deg, #4728c4 0%, #6B4DE6 100%)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                fontFamily:"Times New Roman Condensed",
                letterSpacing:-5,
                fontStyle:"italic",
                fontWeight:400,
                display: 'inline-block',
              }}
              color="#b5a2fa"
            >
              campaign insights
            </Typography>
          </Typography>
          
          <Typography
            sx={{
              mb: 4,
              fontSize: { xs: '18px', md: '20px' },
              color: '#4D4D4D',
              maxWidth: '700px',
              lineHeight: 1.5,
            }}
          >
            Siftsy is the comment section analytics tool influencer marketers rely
            on for multi-platform, campaign-level audience insights.
          </Typography>

          <Box sx={{ display: 'flex', gap: 2, mb: 8 }}>
            <Button
              variant="contained"
              sx={{
                bgcolor: '#4728c4',
                color: 'white',
                px: 4,
                py: 1.75,
                fontSize: '16px',
                fontWeight: 600,
                '&:hover': {
                  bgcolor: '#3B20A3',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(71, 40, 196, 0.25)',
                },
                transition: 'all 0.2s ease-in-out',
                borderRadius: '8px',
                textTransform: 'none',
              }}
            >
              Try for Free
            </Button>
            <Button
              variant="contained"
              sx={{
                bgcolor: '#E6E0FF',
                color: '#6039CF',
                px: 4,
                py: 1.75,
                fontSize: '16px',
                fontWeight: 600,
                '&:hover': {
                  bgcolor: '#D1C7FF',
                  transform: 'translateY(-2px)',
                },
                transition: 'all 0.2s ease-in-out',
                borderRadius: '8px',
                textTransform: 'none',
              }}
            >
              Request a Demo
            </Button>
          </Box>

          <Box sx={{ mb: 8 }}>
            <Typography
              sx={{
                color: '#808080',
                fontSize: '14px',
                mb: 3,
              }}
            >
              Trusted by teams at
            </Typography>
            <TrustedBy />
          </Box>
          <FloatingMetricsCards />
        </Box>
      </Container>
      
      <SentimentSection />
      <UploadSection />
      <TableViewSection />
      <FeaturesSection />
      <TestimonialSection />
      <CTASection />
      <Footer />
      <ScrollToTop />
    </Box>
  );
};

export default LandingPage; 