import { Box } from '@mui/material';
import PepperLogo from './svg/PepperLogo';

const TrustedBy = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: { xs: 4, md: 6 },
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      <Box
        component="img"
        src="/logos/pepper.svg"
        alt="Pepper"
        sx={{
          height: '32px',
          opacity: 0.6,
          transition: 'opacity 0.2s',
          '&:hover': { opacity: 1 },
        }}
      />
      <Box
        component="img"
        src="/logos/mckinney.svg"
        alt="McKinney"
        sx={{
          height: '32px',
          opacity: 0.6,
          transition: 'opacity 0.2s',
          '&:hover': { opacity: 1 },
        }}
      />
      <Box
        component="img"
        src="/logos/imf.svg"
        alt="The Influencer Marketing Factory"
        sx={{
          height: '32px',
          opacity: 0.6,
          transition: 'opacity 0.2s',
          '&:hover': { opacity: 1 },
        }}
      />
      <Box
        component="img"
        src="/logos/sosani.svg"
        alt="Sosani"
        sx={{
          height: '32px',
          opacity: 0.6,
          transition: 'opacity 0.2s',
          '&:hover': { opacity: 1 },
        }}
      />
    </Box>
  );
};

export default TrustedBy; 