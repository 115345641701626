import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import TableViewUI from './TableViewUI';

const TableViewSection = () => {
  return (
    <Box
      sx={{
        py: { xs: 8, md: 12 },
        bgcolor: '#F8F0FF',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Container 
        maxWidth="lg"
        sx={{
          position: 'relative',
        }}
      >
        <Grid container spacing={6} alignItems="center">
          <Grid item xs={12} md={5}>
            <Box sx={{ maxWidth: 480 }}>
              <Typography
                variant="h2"
                component="h2"
                sx={{
                  fontSize: { xs: '32px', md: '48px' },
                  fontWeight: 600,
                  color: '#1A1A1A',
                  mb: 3,
                  lineHeight: 1.2,
                }}
              >
                Every post.{' '}
                <Typography
                  component="span"
                  variant="inherit"
                  sx={{
                    color: '#6039CF',
                    fontStyle: 'italic',
                    display: 'inline-block',
                  }}
                >
                  One place.
                </Typography>
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '16px', md: '18px' },
                  color: '#4D4D4D',
                  lineHeight: 1.6,
                }}
              >
                Siftsy puts all of your influencer campaigns' comment sections in one place, so you evaluate audience success in minutes, not hours. Dashboard and table views.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box
              sx={{
                position: 'relative',
                mx: 'auto',
                maxWidth: '100%',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: -100,
                  right: -100,
                  width: 400,
                  height: 400,
                  background: '#E6E0FF',
                  borderRadius: '50%',
                  filter: 'blur(100px)',
                  opacity: 0.5,
                  zIndex: 0,
                }}
              />
              <Box sx={{ position: 'relative', zIndex: 1 }}>
                <TableViewUI />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default TableViewSection; 