import { Box, Avatar } from '@mui/material';
import { keyframes } from '@mui/system';

const float = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const TableViewUI = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        animation: `${float} 3s ease-in-out infinite`,
      }}
    >
 <img src={`./tableview.svg`} style={{width:"100%"}}/>
    </Box>
  );
};

export default TableViewUI; 