import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import FeatureCards from './FeatureCards';

const FeaturesSection = () => {
  return (
    <Box
      sx={{
        py: { xs: 8, md: 12 },
        bgcolor: '#FFFFFF',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            textAlign: 'center',
            mb: { xs: 6, md: 8 },
          }}
        >
          <Typography
            variant="h2"
            component="h2"
            sx={{
              fontSize: { xs: '32px', md: '48px' },
              fontWeight: 600,
              color: '#1A1A1A',
              mb: 2,
              lineHeight: 1.2,
            }}
          >
            Powerful features for{' '}
            <Typography
              component="span"
              variant="inherit"
              sx={{
                color: '#6039CF',
                fontStyle: 'italic',
                display: 'inline-block',
              }}
            >
              comprehensive reporting
            </Typography>
            .
          </Typography>
        </Box>

        <Box
          sx={{
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: -100,
              left: -200,
              width: 400,
              height: 400,
              background: '#E6E0FF',
              borderRadius: '50%',
              filter: 'blur(100px)',
              opacity: 0.5,
              zIndex: 0,
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: -100,
              right: -200,
              width: 400,
              height: 400,
              background: '#E6E0FF',
              borderRadius: '50%',
              filter: 'blur(100px)',
              opacity: 0.5,
              zIndex: 0,
            },
          }}
        >
          <FeatureCards />
        </Box>
      </Container>
    </Box>
  );
};

export default FeaturesSection; 