import { Box, Typography } from '@mui/material';

const MetricCard = ({ number, label }) => (
  <Box
    sx={{
      bgcolor: 'white',
      p: 3,
      borderRadius: 2,
      boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.08)',
      textAlign: 'center',
      minWidth: '180px',
      transform: 'translateY(0)',
      transition: 'all 0.3s ease',
      '&:hover': {
        transform: 'translateY(-8px)',
        boxShadow: '0px 12px 28px rgba(0, 0, 0, 0.12)',
      },
    }}
  >
    <Typography
      sx={{
        fontSize: '36px',
        fontWeight: 700,
        color: '#4728c4',
        mb: 1,
      }}
    >
      {number}
    </Typography>
    <Typography
      sx={{
        fontSize: '14px',
        color: '#4D4D4D',
        lineHeight: 1.4,
      }}
    >
      {label}
    </Typography>
  </Box>
);

const FloatingMetricsCards = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 3,
        flexWrap: 'wrap',
        justifyContent: 'center',
        mt: -4,
        mb: 8,
        position: 'relative',
        zIndex: 2,
      }}
    >
      <MetricCard number="3M+" label="Comments Analyzed" />
      <MetricCard number="85%" label="Time Saved vs Manual" />
      <MetricCard number="24hr" label="Average Response Time" />
    </Box>
  );
};

export default FloatingMetricsCards; 